import { Row, Pane } from "components/base/layout";
import { Code } from "components/base/type";
import { Select } from "evergreen-ui";
import { Icon, Page, Link } from "components/base";

// const RED = "hsl(22, 89, 64)";
// const GREEN = "hsl(131, 33, 67)";

const VALUES: Record<
  string,
  Record<number, { health: number; H: number; S: number; L: number }>
> = {
  "analytics.order_history": {
    0: {
      health: -1,
      H: 22,
      S: 89,
      L: 64,
    },
    1: {
      health: -0.4,
      H: 22,
      S: 75,
      L: 72,
    },
  },
  "analytics.daily_revenue_rollup": {
    5: {
      health: -0.4,
      H: 22,
      S: 75,
      L: 72,
    },
  },
  "analytics.weekly_revenue_rollup": {
    5: {
      health: -0.4,
      H: 22,
      S: 75,
      L: 72,
    },
  },
  "analytics.monthly_revenue_rollup": {
    5: {
      health: -0.4,
      H: 22,
      S: 75,
      L: 72,
    },
  },
  "analytics.total_revenue": {
    5: {
      health: -0.4,
      H: 22,
      S: 75,
      L: 72,
    },
  },
};

const TABLES = [
  "analytics.customers",
  "analytics.users",
  "analytics.order_history",
  "analytics.emails",
  "analytics.groups",
  "analytics.payment_history",
  "analytics.sessions",
  "analytics.health_scores",
  "analytics.trial_signups",
  "analytics.product_qualified_leads",
  "analytics.daily_activity_rollup",
  "analytics.weekly_activity_rollup",
  "analytics.monthly_activity_rollup",
  "analytics.total_activity",
  "analytics.daily_revenue_rollup",
  "analytics.weekly_revenue_rollup",
  "analytics.monthly_revenue_rollup",
  "analytics.total_revenue",
  "analytics.daily_refund_rollup",
  "analytics.weekly_refund_rollup",
  "analytics.monthly_refund_rollup",
  "analytics.total_refund",
  "analytics.daily_integrations_rollup",
  "analytics.weekly_integrations_rollup",
  "analytics.monthly_integrations_rollup",
  "analytics.total_integrations",
  "analytics.demographic_counts",
  "analytics.clearbit_enrichment",
];

const DIMENSIONS = [
  "Row Count",
  "Cardinality",
  "Queries",
  "Loads",
  "Sync Time",
  "Distributions",
  "Monotonic",
  "Continuous",
  "Custom",
];

const DataPage = () => {
  return (
    <Page
      title="Data Warehouse Overview"
      actions={
        <>
          <Select width={124} marginRight={8}>
            <option>Warehouses</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Roles</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Users</option>
          </Select>
          <Pane
            marginX={23}
            height="100%"
            borderRight="1px solid #E6E8E9"
          ></Pane>
          <Select width={124} marginRight={8}>
            <option>Databases</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Schemas</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Tables</option>
          </Select>
        </>
      }
    >
      <Pane backgroundColor="white" paddingX={38} paddingY={24} height="100%">
        <Row marginBottom={3}>
          <Pane width={300} marginRight={8}></Pane>
          {DIMENSIONS.map((dimension) => (
            <Pane
              width={80}
              boxShadow="inset 0 0 0 1px white"
              color="#425A70"
              fontSize={12}
              textAlign="center"
            >
              {dimension}
            </Pane>
          ))}
        </Row>
        {TABLES.map((tableName, tableIndex) => {
          const rowDimensionHealth = DIMENSIONS.map(
            (dimension, dimensionIndex) => {
              const existingValue =
                VALUES[tableName] && VALUES[tableName][dimensionIndex]
                  ? VALUES[tableName][dimensionIndex]
                  : null;

              if (existingValue) {
                return existingValue;
              }

              const health = Math.random() * 40;
              console.log(tableName, dimensionIndex, health);
              const H = health > 0 ? 131 : 22;
              const S =
                health > 5
                  ? 33
                  : Math.round(
                      health > 0 ? 10 + (health / 5) * 23 : health * -89
                    );
              const L = 67;
              return { health, H, S, L };
            }
          );
          const HEALTH_DANGER = "HEALTH_DANGER";
          const HEALTH_WARNING = "HEALTH_WARNING";
          const HEALTH_OK = "HEALTH_OK";
          const rowStatus = rowDimensionHealth.find(
            (dimension) => dimension.health < -0.5
          )
            ? HEALTH_DANGER
            : rowDimensionHealth.find((dimension) => dimension.health < 0)
            ? HEALTH_WARNING
            : HEALTH_OK;
          return (
            <Row centerY>
              <Pane textAlign="right" width={300} marginRight={8}>
                <Link to={`/data/${tableName}`}>
                  <Code fontSize={13} color="#425A70">
                    {tableName}
                  </Code>
                </Link>
              </Pane>
              {DIMENSIONS.map((dimension, dimensionIndex) => {
                return (
                  <Pane
                    width={80}
                    height={26}
                    boxShadow="inset 0 0 0 1px white"
                    color="#425A70"
                    fontSize={12}
                    textAlign="center"
                    backgroundColor={`hsl(${rowDimensionHealth[dimensionIndex].H}, ${rowDimensionHealth[dimensionIndex].S}%, ${rowDimensionHealth[dimensionIndex].L}%)`}
                  ></Pane>
                );
              })}
              <Icon
                type="far"
                name={
                  rowStatus === HEALTH_OK
                    ? "fa-check-circle"
                    : rowStatus === HEALTH_WARNING
                    ? "fa-exclamation-triangle"
                    : "fa-exclamation-circle"
                }
                color={
                  rowStatus === HEALTH_OK
                    ? "#47B881"
                    : rowStatus === HEALTH_WARNING
                    ? "#D9822B"
                    : "#EC4C47"
                }
                marginLeft={11}
              />
            </Row>
          );
        })}
      </Pane>
    </Page>
  );
};

export default DataPage;
