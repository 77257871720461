import { Row } from "components/base/layout";
import { H1 } from "components/base/type";
import { UIProps } from "components/base/types";

export const Page = ({
  title,
  actions,
  children,
  ...props
}: {
  title: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
} & UIProps) => {
  return (
    <>
      <Row
        borderBottom="1px solid #DFE1E1"
        paddingX={26}
        paddingY={15}
        minHeight={63}
        {...props}
      >
        <H1 color="#234361" alignSelf="center">
          {title}
        </H1>
        {actions && <Row marginLeft="auto">{actions}</Row>}
      </Row>
      <Row
        backgroundColor="#EEF0F4"
        padding={21}
        flexGrow={1}
        flexWrap="wrap"
        centerX
      >
        {children}
      </Row>
    </>
  );
};

export default Page;
