import { useParams } from "react-router-dom";
import { Select, Button, Table, SearchTemplateIcon } from "evergreen-ui";
import { Pane, Row } from "components/base/layout";
import { Page, Image, Icon, Link } from "components/base";
import { H4 } from "components/base/type";
import { UIProps } from "components/base/types";

const EVENTS = [
  {
    datetime: "Jan 15 2021 3:00pm EST",
    metric: "Row count",
    count: "1.39M",
    status: "OK",
  },
  {
    datetime: "Jan 15 2021 6:00pm EST",
    metric: "Row count",
    count: "1.4M",
    status: "OK",
  },
  {
    datetime: "Jan 15 2021 9:00pm EST",
    metric: "Row count",
    count: "1.41M",
    status: "OK",
  },
  {
    datetime: "Jan 16 2021 12:00am EST",
    metric: "Row count",
    count: "350K",
    status: "Error",
  },
  {
    datetime: "Jan 16 2021 3:00am EST",
    metric: "Row count",
    count: "350K",
    status: "Error",
  },
];

const ChartPage = () => {
  const { tableSlug, chartSlug } = useParams<{
    tableSlug: string;
    chartSlug: string;
  }>();

  const chartName = chartSlug
    .split("-")
    .map((part, i) => (i === 0 ? part[0].toUpperCase() + part.slice(1) : part))
    .join(" ");

  return (
    <Page
      title={`Data / ${tableSlug} table / ${chartName}`}
      actions={
        <>
          <Select width={124} marginRight={8}>
            <option>Warehouses</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Roles</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Users</option>
          </Select>
        </>
      }
    >
      <ChartContainer
        id={`${tableSlug}/row-count`}
        title="Row count"
        updateTime="3h"
        statusText="Row counts significantly below normal"
      >
        <Row>
          <Pane>
            <Image maxHeight="100%" width="100%" src="/chart1-large.svg" />

            <Table marginTop={40} color="#425A70">
              <Table.Head>
                <Table.TextHeaderCell flexBasis={150}>
                  Datetime
                </Table.TextHeaderCell>
                <Table.TextHeaderCell flexBasis={120}>
                  Metric
                </Table.TextHeaderCell>
                <Table.TextHeaderCell>Count</Table.TextHeaderCell>
                <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                <Table.TextHeaderCell>System</Table.TextHeaderCell>
                <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={240}>
                {EVENTS.map((event) => (
                  <Table.Row key={event.datetime} isSelectable>
                    <Table.TextCell flexBasis={150}>
                      {event.datetime}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={120}>
                      {event.metric}
                    </Table.TextCell>
                    <Table.TextCell>{event.count}</Table.TextCell>
                    <Table.TextCell>
                      <Row centerY>
                        <Icon
                          name="fa-circle"
                          type="fas"
                          fontSize={6}
                          marginRight={8}
                          color={event.status === "OK" ? "#47B881" : "#EB5757"}
                        />

                        {event.status}
                      </Row>
                    </Table.TextCell>
                    <Table.TextCell>
                      <Pane
                        borderRadius={3}
                        border="1px solid #E6E8E9"
                        height={30}
                        width={30}
                        padding={5}
                      >
                        <Image
                          src="/snowflake.svg"
                          height="100%"
                          width="100%"
                        />
                      </Pane>
                    </Table.TextCell>
                    <Table.TextCell>
                      <Link to="/logs">
                        <Row centerY color="#66788A">
                          <SearchTemplateIcon marginRight={8} />
                          Logs
                        </Row>
                      </Link>
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
          <Pane
            flexShrink={0}
            paddingLeft={34}
            marginLeft={94}
            width={400}
            borderLeft="1px solid #E6E8E9"
          >
            <H4 marginBottom={11}>Monitors</H4>
            <Pane
              backgroundColor="#FAE2E2"
              paddingY={18}
              paddingX={23}
              fontSize={14}
            >
              <Icon
                marginRight={8}
                fontSize={16}
                type="far"
                name="fa-exclamation-circle"
                color="#EC4C47"
              />
              Row counts significantly below normal
            </Pane>
            <Pane>
              <Pane
                letterSpacing="0.3px"
                color="#66788A"
                fontWeight={600}
                fontSize={12}
                lineHeight="16px"
                marginTop={35}
                marginBottom={5}
              >
                AUTOMATED
              </Pane>
              <Row
                centerY
                border="1px solid #DDE1E5"
                paddingY={14}
                paddingX={17}
                borderRadius={5}
                fontSize={12}
                color="#234361"
              >
                Count compared to historical average
                <Icon
                  marginLeft="auto"
                  fontSize={16}
                  type="far"
                  name="fa-edit"
                  color="#BDBDBD"
                />
              </Row>
            </Pane>

            <Pane>
              <Pane
                letterSpacing="0.3px"
                color="#66788A"
                fontWeight={600}
                fontSize={12}
                lineHeight="16px"
                marginTop={35}
                marginBottom={5}
              >
                MANUAL
              </Pane>
              <Button>+ Monitor</Button>
            </Pane>
          </Pane>
        </Row>
      </ChartContainer>
    </Page>
  );
};

const ChartContainer = ({
  children,
  id,
  title,
  statusText,
  updateTime,
  ...props
}: {
  id: string;
  title: string;
  updateTime: string;
  statusText: string;
} & UIProps) => (
  <Pane
    backgroundColor="white"
    paddingX={24}
    paddingY={24}
    width="100%"
    borderRadius={5}
    marginRight={35}
    height="fit-content"
    {...props}
  >
    <Row marginBottom={24} alignItems="flex-start">
      <Pane>
        <Pane fontSize={16} fontWeight={500}>
          {title}
        </Pane>
      </Pane>
      <Row marginLeft="auto" color="#BDBDBD" centerY fontSize={14}>
        <Pane
          paddingY={2}
          paddingX={6}
          fontSize={12}
          backgroundColor="#DEDBDB"
          borderRadius={3}
          color="black"
        >
          {updateTime}
        </Pane>
        <Link to={`/data/${id}`}>
          <Icon marginLeft={16} type="far" name="fa-expand-alt" />
        </Link>
        <Icon marginLeft={16} type="far" name="fa-clone" />
        <Icon marginLeft={16} type="far" name="fa-bell" />
        <Icon marginLeft={16} type="far" name="fa-bars" />
      </Row>
    </Row>
    <Row paddingX={24} center>
      {children}
    </Row>
  </Pane>
);

export default ChartPage;
