import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import classNames from "classnames";
import Box from "ui-box";
import { UIProps } from "components/base/types";

import styled from "styled-components";

interface ILinkStyleProps {
  unstyled?: boolean;
  defaultOpacity?: number;
  to?: string;
}

type LinkProps = {
  active?: boolean;
  activeProps?: object;
  highlight?: boolean;
} & ILinkStyleProps &
  Partial<RouterLinkProps> &
  UIProps;

const StyledLink = styled<React.FC<ILinkStyleProps>>(
  ({ defaultOpacity, unstyled, to, ...rest }) =>
    to ? <RouterLink to={to} {...rest} /> : <Box is="span" {...rest} />
)`
  ${(props) =>
    !props.unstyled &&
    `
  color: inherit;
  text-decoration: none;
  opacity: ${props.defaultOpacity !== undefined ? props.defaultOpacity : 1};

  &[disabled] {
    color: ${props.theme["$black-0"]};
    opacity: 0.25;
    pointer-events: none;
  }

  &:hover {
    color: ${props.theme["$black-0"]};
    opacity: 0.8;
  }

  &.highlight {
    color: hsl(197, 71%, 52%);
    opacity: 1;

    &:hover {
      color: hsl(197, 71%, 42%);
      opacity: 1;
    }

    &[disabled] {
      color: hsl(197, 10%, 52%);
      opacity: 0.5;
    }
  }

  .highlightOnHover {
    transition: color 0.2s ease;
  }

  &:hover .highlightOnHover {
    color: hsl(197,71%,52%);
  }

  &.active,
  &.active:hover {
    opacity: 1;
  }`}
`;

export const Link = ({
  to,
  active,
  highlight,
  activeProps,
  className,
  ...props
}: LinkProps) => (
  <Box
    is={StyledLink}
    to={to || ""}
    className={classNames(
      active && "active",
      highlight && "highlight",
      className
    )}
    fontWeight={500}
    cursor="pointer"
    {...props}
    {...(active ? activeProps : {})}
  />
);

export default Link;
