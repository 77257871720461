import { ReactNode } from "react";
import styled from "styled-components";
import classNames from "classnames";

import { Column } from "components/base/layout";
import { Image, Icon } from "components/base";
import { UIProps } from "components/base/types";

export const IntegrationCard = ({
  active,
  disabled,
  children,
  ...props
}: {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
} & UIProps) => (
  <StyledCard
    className={classNames(active && "active")}
    position="relative"
    marginRight={25}
    marginBottom={25}
    center
    width={262}
    height={110}
    paddingX={20}
    paddingY={10}
    backgroundColor="white"
    border="2px solid transparent"
    borderRadius={6}
    opacity={disabled ? 0.2 : 1}
    pointerEvents={disabled ? "none" : "all"}
    boxShadow="rgb(71 88 114 / 10%) 0px 2px 6px"
    {...props}
  >
    {children}
    {active && (
      <Icon
        position="absolute"
        className="visibleOnHover"
        name="fa-cog"
        top={12}
        right={12}
      />
    )}
  </StyledCard>
);

IntegrationCard.Image = (props: {
  logoFileName: string;
  maxWidth?: number;
}) => (
  <Image
    width="100%"
    maxWidth={props.maxWidth || 225}
    maxHeight={90}
    height="auto"
    src={`/integration-logos/${props.logoFileName}`}
  />
);

const StyledCard = styled(Column)`
  cursor: pointer;
  transition: border-color 0.2s ease 0s, box-shadow 0.2s ease 0s;
  &:hover,
  &.active {
    border-color: rgb(5, 219, 153);
    background-color: rgb(71 88 114 / 16%) 0px 4px 16px;
  }
  .visibleOnHover {
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  &.active:hover .visibleOnHover {
    opacity: 0.5;
  }
`;

export default IntegrationCard;
