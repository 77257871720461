import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocalStorage } from "@rehooks/local-storage";
import { Row, Column } from "components/base/layout";
import Sidebar from "./Sidebar";
import { TextInput } from "evergreen-ui";
import DataPage from "pages/DataPage";
import TablePage from "pages/TablePage";
import ChartPage from "pages/ChartPage";
import LogsPage from "pages/LogsPage";
import SettingsPage from "pages/SettingsPage";
import SlackCallbackPage from "pages/SlackCallbackPage";

const App = () => {
  const [password, setPassword] = useLocalStorage("__mpq-password", "");

  if (password !== "pacho") {
    return (
      <Row height="100vh" width="100vw" center>
        <TextInput
          placeholder="Password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
      </Row>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/slack/auth">
          <SlackCallbackPage />
        </Route>
        <Route>
          <Row minHeight="100vh">
            <Sidebar />
            <Column flexGrow={1}>
              <Switch>
                <Route path="/data/:tableSlug/:chartSlug">
                  <ChartPage />
                </Route>
                <Route path="/data/:tableSlug">
                  <TablePage />
                </Route>
                <Route path="/data">
                  <DataPage />
                </Route>
                <Route path="/logs">
                  <LogsPage />
                </Route>
                <Route path="/settings">
                  <SettingsPage />
                </Route>
              </Switch>
            </Column>
          </Row>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
