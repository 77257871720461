import { useParams } from "react-router-dom";
import { Select } from "evergreen-ui";
import { Pane, Row } from "components/base/layout";
import { Page, Image, Icon, Link } from "components/base";
import { UIProps } from "components/base/types";

const TablePage = () => {
  const { tableSlug } = useParams<{
    tableSlug: string;
  }>();

  return (
    <Page
      title={`Data / ${tableSlug} table`}
      actions={
        <>
          <Select width={124} marginRight={8}>
            <option>Warehouses</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Roles</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Users</option>
          </Select>
        </>
      }
    >
      <ChartContainer
        id={`${tableSlug}/row-count`}
        title="Row count"
        updateTime="3h"
        statusIcon={
          <Icon
            marginRight={8}
            fontSize={16}
            type="far"
            name="fa-exclamation-circle"
            color="#EC4C47"
          />
        }
        statusText="Row counts significantly below normal"
      >
        <Image width="100%" src="/chart1.svg" />
      </ChartContainer>
      <ChartContainer
        id={`${tableSlug}/loads`}
        title="Loads"
        updateTime="8h"
        statusIcon={
          <Icon
            marginRight={8}
            fontSize={16}
            type="far"
            name="fa-check-circle"
            color="#47B881"
          />
        }
        statusText="Table is loaded on expected schedule"
      >
        <Image width="100%" src="/chart2.svg" />
      </ChartContainer>
      <ChartContainer
        id={`${tableSlug}/order-amount-distribution`}
        title="Order amount distribution"
        updateTime="1d"
        statusIcon={
          <Icon
            marginRight={8}
            fontSize={16}
            type="far"
            name="fa-exclamation-triangle"
            color="#D9822B"
          />
        }
        statusText="Unexpected distribution of order amount column"
      >
        <Image width="100%" src="/chart3.svg" />
      </ChartContainer>
      <ChartContainer
        id={`${tableSlug}/sync-time`}
        title="Sync time"
        updateTime="6h"
        statusIcon={
          <Icon
            marginRight={8}
            fontSize={16}
            type="far"
            name="fa-check-circle"
            color="#47B881"
          />
        }
        statusText="Sync time within normal threshold"
      >
        <Image width="100%" src="/chart4.svg" />
      </ChartContainer>
    </Page>
  );
};

const ChartContainer = ({
  children,
  id,
  title,
  statusText,
  statusIcon,
  updateTime,
  ...props
}: {
  id: string;
  title: string;
  updateTime: string;
  statusText: string;
  statusIcon: React.ReactNode;
} & UIProps) => (
  <Pane
    backgroundColor="white"
    paddingX={24}
    paddingY={24}
    height="fit-content"
    width={574}
    borderRadius={5}
    marginRight={35}
    marginBottom={20}
    {...props}
  >
    <Row marginBottom={24} alignItems="flex-start">
      <Pane>
        <Pane fontSize={16} fontWeight={500}>
          {title}
        </Pane>
        <Row fontSize={13} color="#66788A" centerY marginTop={6}>
          {statusIcon}
          <Pane marginTop={-2}>{statusText}</Pane>
        </Row>
      </Pane>
      <Row marginLeft="auto" color="#BDBDBD" centerY fontSize={14}>
        <Pane
          paddingY={2}
          paddingX={6}
          fontSize={12}
          backgroundColor="#DEDBDB"
          borderRadius={3}
          color="black"
        >
          {updateTime}
        </Pane>
        <Link to={`/data/${id}`}>
          <Icon marginLeft={16} type="far" name="fa-expand-alt" />
        </Link>
        <Icon marginLeft={16} type="far" name="fa-clone" />
        <Icon marginLeft={16} type="far" name="fa-bell" />
        <Icon marginLeft={16} type="far" name="fa-bars" />
      </Row>
    </Row>
    <Row paddingX={24} height={220} center>
      {children}
    </Row>
  </Pane>
);

export default TablePage;
