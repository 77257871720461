import React from "react";
import { Pane } from "components/base/layout";
import { TypographyProps, UIProps } from "components/base/types";

export const Typography = ({ truncate, ...props }: TypographyProps) => {
  const options: Partial<
    Pick<
      UIProps,
      "title" | "whiteSpace" | "overflow" | "textOverflow" | "display"
    >
  > = {};
  if (truncate) {
    options.whiteSpace = "nowrap";
    options.overflow = "hidden";
    options.textOverflow = "ellipsis";
    options.display = "block";
    options.title = props.children
      ? props.children.join
        ? props.children.join("").toString()
        : props.children.toString()
      : "";
  }

  return <Pane {...options} {...props} />;
};

export default Typography;
