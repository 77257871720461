import { Select } from "evergreen-ui";
import { Pane, Row } from "components/base/layout";
import { Page, Image, Icon } from "components/base";

const LOGS = [
  {
    timestamp: "Jan 15 02:36:42",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 15 02:43:16",
    sourceImageSrc: "/ge.png",
    text: "Great Expectations test suite passed (16/16)",
  },
  {
    timestamp: "Jan 15 02:48:26",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 15 02:55:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 15 03:16:36",
    sourceImageSrc: "/github.png",
    text: "dbt Github repository pull request merged",
  },
  {
    timestamp: "Jan 15 03:27:09",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.CUSTOMERS by DS_USER",
  },
  {
    timestamp: "Jan 15 03:46:12",
    sourceImageSrc: "/dbt.png",
    text: "dbt job finished in 5 minutes and 20 seconds",
  },
  {
    timestamp: "Jan 15 04:46:24",
    sourceImageSrc: "/airflow.png",
    text: "Airflow DAG completed in 3 minutes",
  },
  {
    timestamp: "Jan 15 06:52:31",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 15 07:46:17",
    sourceImageSrc: "/ge.png",
    text: "Great Expectations test suite passed (16/16)",
  },
  {
    timestamp: "Jan 15 08:56:39",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 15 11:56:39",
    sourceImageSrc: "/snowflake.svg",
    text:
      "Failed periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
    status: "FAIL",
  },
  {
    timestamp: "Jan 15 02:46:07",
    sourceImageSrc: "/snowflake.svg",
    text: "Failed periodic query run against ANALYTICS.USERS by REPORTING_USER",
    status: "FAIL",
  },
  {
    timestamp: "Jan 15 03:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 16 04:51:22",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 16 05:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 16 04:51:12",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 16 07:16:33",
    sourceImageSrc: "/airflow.png",
    text: "Airflow DAG completed in 3 minutes",
  },
  {
    timestamp: "Jan 16 07:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.ACCOUNTS by ANALYTICS_ENGINEER_USER",
  },
  {
    timestamp: "Jan 16 02:36:42",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 16 02:43:16",
    sourceImageSrc: "/ge.png",
    text: "Great Expectations test suite passed (16/16)",
  },
  {
    timestamp: "Jan 16 02:48:26",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 16 02:55:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 16 03:16:36",
    sourceImageSrc: "/github.png",
    text: "dbt Github repository pull request merged",
  },
  {
    timestamp: "Jan 16 03:27:09",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.CUSTOMERS by DS_USER",
  },
  {
    timestamp: "Jan 16 03:46:12",
    sourceImageSrc: "/dbt.png",
    text: "dbt job finished in 5 minutes and 20 seconds",
  },
  {
    timestamp: "Jan 16 04:46:24",
    sourceImageSrc: "/airflow.png",
    text: "Airflow DAG completed in 3 minutes",
  },
  {
    timestamp: "Jan 16 06:52:31",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 16 07:46:17",
    sourceImageSrc: "/ge.png",
    text: "Great Expectations test suite passed (16/16)",
  },
  {
    timestamp: "Jan 16 08:56:39",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 16 11:56:39",
    sourceImageSrc: "/snowflake.svg",
    text:
      "Failed periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 16 02:46:07",
    sourceImageSrc: "/snowflake.svg",
    text: "Failed periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 16 03:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 17 04:51:22",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 17 05:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 17 04:51:12",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 17 07:16:33",
    sourceImageSrc: "/airflow.png",
    text: "Airflow DAG completed in 3 minutes",
  },
  {
    timestamp: "Jan 17 07:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.ACCOUNTS by ANALYTICS_ENGINEER_USER",
  },
  {
    timestamp: "Jan 17 02:36:42",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 17 02:43:16",
    sourceImageSrc: "/ge.png",
    text: "Great Expectations test suite passed (16/16)",
  },
  {
    timestamp: "Jan 17 02:48:26",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 17 02:55:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 17 03:16:36",
    sourceImageSrc: "/github.png",
    text: "dbt Github repository pull request merged",
  },
  {
    timestamp: "Jan 17 03:27:09",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.CUSTOMERS by DS_USER",
  },
  {
    timestamp: "Jan 17 03:46:12",
    sourceImageSrc: "/dbt.png",
    text: "dbt job finished in 5 minutes and 20 seconds",
  },
  {
    timestamp: "Jan 17 04:46:24",
    sourceImageSrc: "/airflow.png",
    text: "Airflow DAG completed in 3 minutes",
  },
  {
    timestamp: "Jan 17 06:52:31",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 17 07:46:17",
    sourceImageSrc: "/ge.png",
    text: "Great Expectations test suite passed (16/16)",
  },
  {
    timestamp: "Jan 17 08:56:39",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 17 11:56:39",
    sourceImageSrc: "/snowflake.svg",
    text:
      "Failed periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 17 02:46:07",
    sourceImageSrc: "/snowflake.svg",
    text: "Failed periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 17 03:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.USERS",
  },
  {
    timestamp: "Jan 18 04:51:22",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 18 05:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.ACCOUNTS by REPORTING_USER",
  },
  {
    timestamp: "Jan 18 04:51:12",
    sourceImageSrc: "/snowflake.svg",
    text: "Periodic query run against ANALYTICS.USERS by REPORTING_USER",
  },
  {
    timestamp: "Jan 18 07:16:33",
    sourceImageSrc: "/airflow.png",
    text: "Airflow DAG completed in 3 minutes",
  },
  {
    timestamp: "Jan 18 07:46:52",
    sourceImageSrc: "/snowflake.svg",
    text: "New query run against ANALYTICS.ACCOUNTS by ANALYTICS_ENGINEER_USER",
  },
];

const LogsPage = () => {
  return (
    <Page
      title="Log stream"
      actions={
        <>
          <Select width={124} marginRight={8}>
            <option>Data Sources</option>
          </Select>
          <Select width={124} marginRight={8}>
            <option>Levels</option>
          </Select>
        </>
      }
    >
      <Row height={746} width="100%">
        <Pane
          backgroundColor="white"
          paddingX={24}
          paddingY={24}
          height="fit-content"
          borderRadius={5}
          marginRight={16}
          marginBottom={20}
          width="100%"
          maxHeight="100%"
          overflowY="scroll"
          position="relative"
        >
          <Pane
            position="absolute"
            left={0}
            right={0}
            top={311}
            borderBottom="1px solid #CF3C31"
            opacity={0.7}
          />
          <Row marginBottom={20} fontSize={14} centerY fontWeight={600}>
            <Pane marginLeft={22} width={57} marginRight={8}>
              Source
            </Pane>
            <Pane minWidth={150} marginRight={8}>
              Datetime
            </Pane>
            <Pane>Content</Pane>
          </Row>
          {LOGS.map((log) => (
            <Row marginBottom={12} fontSize={14} centerY>
              <Row
                center
                width={20}
                height={20}
                marginRight={36}
                marginLeft={31}
              >
                <Image src={log.sourceImageSrc} width="100%" />
              </Row>
              <Pane
                color="#EC4C47"
                minWidth={150}
                marginRight={8}
                fontFamily="Fira Code"
              >
                [{log.timestamp}]
              </Pane>
              <Pane>{log.text}</Pane>
              {log.status && log.status === "FAIL" && (
                <Icon
                  fontSize={16}
                  type="far"
                  name="fa-exclamation-circle"
                  color="#EC4C47"
                  marginLeft="auto"
                />
              )}
            </Row>
          ))}
        </Pane>
        <Pane marginRight={20}>
          <Image src="/logs-sidebar.svg" />
        </Pane>
      </Row>
    </Page>
  );
};

export default LogsPage;
