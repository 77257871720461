import React from "react";
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from "react-router-dom";
import Box from "ui-box";
import { UIProps } from "components/base/types";

import styled from "styled-components";

interface INavLinkStyleProps {
  defaultOpacity?: number;
  to: string;
}

const StyledNavLink = styled<React.FC<INavLinkStyleProps>>(
  ({ defaultOpacity, ...rest }) => <RouterNavLink {...rest} />
)`
  && {
    display: block;
    font-weight: inherit;
    font-size: 14px;
    color: inherit;
    text-decoration: none;
    border-radius: 4px;

    &[disabled] {
      opacity: 0.15;
    }

    &:hover {
      opacity: 0.8;
    }

    &.active,
    &.active:hover {
      opacity: 1;
      background-color: #60788a;
    }
  }
`;

export type NavLinkProps = RouterNavLinkProps & INavLinkStyleProps & UIProps;

export const NavLink = (props: NavLinkProps) => (
  <Box is={StyledNavLink} {...props} />
);

export default NavLink;
