import { useQuery } from "utils/hooks";
import { SLACK_INTEGRATION_ID_STORAGE_KEY } from "constants/storageKeys";

const SlackCallbackPage = () => {
  const query = useQuery();
  const code = query.get("code");
  const state = query.get("state");
  if (code && code.length > 0) {
    window.localStorage.setItem(
      SLACK_INTEGRATION_ID_STORAGE_KEY,
      JSON.stringify({
        code,
        state,
      })
    );
  }

  return <></>;
};

export default SlackCallbackPage;
