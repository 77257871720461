import { useState, useEffect } from "react";
import useLocalStorage from "@rehooks/local-storage";
import { Dialog, Select, toaster } from "evergreen-ui";

import { usePrevious, useWaitForLocalStorageValue } from "utils/hooks";
import { SLACK_INTEGRATION_ID_STORAGE_KEY } from "constants/storageKeys";
import { Connection, ConnectionType } from "types/Connection";
import IntegrationCard from "./IntegrationCard";
import { Pane } from "components/base/layout";

const SLACK_CONNECTION: Connection = {
  name: "Slack",
  type: ConnectionType.Slack,
  logoFileName: "integration-tile_slack.svg",
  maxWidth: 100,
};

const SLACK_CONNECT_URL =
  "https://slack.com/oauth/v2/authorize?client_id=387049038887.1854095536164&scope=channels:read,chat:write,commands,links:read,links:write,users:read,users:read.email,users.profile:read,groups:read&user_scope=";

const SlackIntegrationCard = () => {
  const [
    slackAuthorizationObject,
    setSlackAuthorizationObject,
  ] = useLocalStorage(SLACK_INTEGRATION_ID_STORAGE_KEY, "");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [connectionWindow, setConnectionWindow] = useState<Window | null>(null);
  const [channel, setChannel] = useState("");
  // const slackAuthorizationObject = useWaitForLocalStorageValue({
  //   localStorageKey: SLACK_INTEGRATION_ID_STORAGE_KEY,
  //   shouldBeginPolling: !!connectionWindow,
  // });
  const prevSlackAuthorizationObject = usePrevious(slackAuthorizationObject);

  const hasSlackAuthorizationObjectChanged =
    prevSlackAuthorizationObject !== slackAuthorizationObject;

  const source = SLACK_CONNECTION;
  const slackIntegrations = [];

  useEffect(() => {
    if (
      slackAuthorizationObject &&
      hasSlackAuthorizationObjectChanged &&
      connectionWindow
    ) {
      // const { code, state }: { code: string; state: string } = JSON.parse(
      //   slackAuthorizationObject
      // );

      // create slack integration

      // .catch((error) => {
      //   toaster.danger(
      //     "Sorry, we had trouble connecting to your Slack. Can you please try again?"
      //   );
      // })
      // .finally(() => {
      // window.localStorage.removeItem(SLACK_INTEGRATION_ID_STORAGE_KEY);
      connectionWindow.close();
      setConnectionWindow(null);
      setIsSettingsOpen(true);
      // });
    }
  }, [
    slackAuthorizationObject,
    connectionWindow,
    hasSlackAuthorizationObjectChanged,
  ]);

  console.log("isSettingsOpen", isSettingsOpen);

  useEffect(() => {
    if (isSavingSettings) {
      window.setTimeout(() => {
        setIsSettingsOpen(false);

        window.setTimeout(() => {
          toaster.success(`Slack connection to #${channel} complete.`);
        }, 200);
      }, 2500);
    }
  }, [isSavingSettings, channel]);

  return (
    <>
      <Dialog
        width={400}
        isShown={isSettingsOpen}
        title="Slack Connection Settings"
        onCloseComplete={() => {
          setIsSavingSettings(false);
          setIsSettingsOpen(false);
        }}
        onConfirm={() => setIsSavingSettings(true)}
        isConfirmLoading={isSavingSettings}
        confirmLabel="Save"
      >
        <Pane marginBottom={18} fontSize={14} opacity={0.8} lineHeight={1.6}>
          Metaplane will send alerts to selected channels in your Slack
          workspace.
        </Pane>

        <Pane marginBottom={8} fontSize={14} fontWeight={500}>
          Select a channel
        </Pane>

        <Select
          value={channel}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setChannel(event.target.value)
          }
        >
          <option value="general" selected>
            general
          </option>
          <option value="engineering">engineering</option>
          <option value="random">random</option>
        </Select>
      </Dialog>
      <IntegrationCard
        disabled={source.disabled || slackIntegrations.length > 0}
        active={!!slackAuthorizationObject}
        onClick={() => {
          console.log("clicked");
          if (!slackAuthorizationObject) {
            // window.localStorage.removeItem(SLACK_INTEGRATION_ID_STORAGE_KEY);
            setConnectionWindow(
              window.open(SLACK_CONNECT_URL, "_blank", "height=800,width=525")
            );
          } else {
            console.log("setting");
            setIsSettingsOpen(true);
          }
        }}
      >
        <IntegrationCard.Image
          logoFileName={source.logoFileName}
          maxWidth={source.maxWidth}
        />
      </IntegrationCard>
    </>
  );
};

export default SlackIntegrationCard;
