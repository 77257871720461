import { useState, useEffect } from "react";
import useLocalStorage from "@rehooks/local-storage";
import { Dialog, Select, toaster } from "evergreen-ui";

import { Connection, ConnectionType } from "types/Connection";
import IntegrationCard from "./IntegrationCard";
import { Pane } from "components/base/layout";

// const SLACK_CONNECTION: Connection = {
//   name: "Slack",
//   type: ConnectionType.Slack,
//   logoFileName: "integration-tile_slack.svg",
//   maxWidth: 100,
// };

const StandardIntegrationCard = ({ source }: { source: Connection }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const existingSource = "";

  console.log("isSettingsOpen", isSettingsOpen);

  useEffect(() => {
    if (isSavingSettings) {
      window.setTimeout(() => {
        setIsSettingsOpen(false);

        window.setTimeout(() => {
          toaster.success(`Connection complete.`);
        }, 200);
      }, 2500);
    }
  }, [isSavingSettings]);

  return (
    <>
      <Dialog
        width={400}
        isShown={isSettingsOpen}
        title="XXX Connection Settings"
        onCloseComplete={() => {
          setIsSavingSettings(false);
          setIsSettingsOpen(false);
        }}
        onConfirm={() => setIsSavingSettings(true)}
        isConfirmLoading={isSavingSettings}
        confirmLabel="Save"
      >
        <Pane>OPTIONS</Pane>
      </Dialog>
      <IntegrationCard
        disabled={source.disabled}
        active={!!existingSource}
        onClick={() => {
          if (!existingSource) {
            // create source
          } else {
            // edit source
            setIsSettingsOpen(true);
          }
        }}
      >
        <IntegrationCard.Image
          logoFileName={source.logoFileName}
          maxWidth={source.maxWidth}
        />
      </IntegrationCard>
    </>
  );
};

export default StandardIntegrationCard;
