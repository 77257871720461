import { useState } from "react";
import { Pane, Row } from "components/base/layout";
import { Page } from "components/base";
import { Tab, Tabs, TabId } from "@blueprintjs/core";
import IntegrationsTab from "./IntegrationsTab";

const SettingsPage = () => {
  const [selectedTabId, setSelectedTabId] = useState<TabId>("integrations");
  return (
    <Page title="Settings" borderWidth={0}>
      <Row width="calc(100% + 42px)" marginX={-21} marginTop={-27}>
        <Tabs
          id="settings-tabs"
          onChange={(newTabId) => setSelectedTabId(newTabId)}
          selectedTabId={selectedTabId}
        >
          <Tab
            id="integrations"
            title="Integrations"
            panel={<IntegrationsTab />}
          />
        </Tabs>
      </Row>
    </Page>
  );
};

export default SettingsPage;
