import React from "react";
import { Typography } from "./Typography";
import { TypographyProps } from "components/base/types";

export const H1 = (props: TypographyProps) => (
  <Typography
    is="h1"
    margin={0}
    fontSize={20}
    fontWeight={600}
    fontFamily="system-ui"
    color="$gray-7"
    {...props}
  />
);
