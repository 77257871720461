import React, { useState } from "react";
import { Pane, Row } from "components/base/layout";
import { Page } from "components/base";
import { Button } from "evergreen-ui";
import SlackIntegrationCard from "./SlackIntegrationCard";
import { Connection, ConnectionType } from "types/Connection";
import IntegrationCard from "./IntegrationCard";
import StandardIntegrationCard from "./StandardIntegrationCard";

const DATABASES: Connection[] = [
  {
    name: "Snowflake",
    type: ConnectionType.Snowflake,
    logoFileName: "integration-tile_snowflake.svg",
  },
  {
    name: "Google BigQuery",
    type: ConnectionType.BigQuery,
    logoFileName: "integration-tile_google-big-query.svg",
  },
  {
    name: "PostgreSQL",
    type: ConnectionType.PostgreSQL,
    logoFileName: "integration-tile_postgres-sql.svg",
    disabled: true,
  },
  {
    name: "Amazon Redshift",
    type: ConnectionType.Redshift,
    logoFileName: "integration-tile_redshift.svg",
    disabled: true,
  },
];

const IntegrationsTab = () => {
  return (
    <Row paddingX={21}>
      <Pane
        paddingX={4}
        paddingY={24}
        height="fit-content"
        width={584}
        borderRadius={5}
        marginRight={35}
        marginBottom={20}
      >
        <Pane fontSize={16} fontWeight={500} marginBottom={22}>
          Warehouses
        </Pane>
        <Row flexWrap="wrap">
          {DATABASES.map((source) => (
            <StandardIntegrationCard key={source.name} source={source} />
          ))}
        </Row>
      </Pane>
      <Pane
        paddingX={24}
        paddingY={24}
        height="fit-content"
        width={584}
        borderRadius={5}
        marginRight={35}
        marginBottom={20}
      >
        <Pane fontSize={16} fontWeight={500} marginBottom={22}>
          Alerts
        </Pane>

        <SlackIntegrationCard />
      </Pane>
    </Row>
  );
};

export default IntegrationsTab;
