import { Pane, Column } from "components/base/layout";
import { Icon, NavLink, Image } from "components/base";
import { UIProps } from "components/base/types";

const Sidebar = () => (
  <Column backgroundColor="#2C373E" color="white" paddingX={10} paddingY={24}>
    <Pane fontWeight={600} fontSize={17} paddingX={10} maxWidth={150}>
      <Image src="/logo-text.white.svg" width="100%" />
    </Pane>
    <Pane marginTop={120}>
      <SidebarRow to="/" icon="fa-house" exact>
        Home
      </SidebarRow>
      <SidebarRow to="/logs" icon="fa-bars">
        Logs
      </SidebarRow>
      <SidebarRow to="/ingestion" icon="fa-filter">
        Ingestion
      </SidebarRow>
      <SidebarRow to="/transformation" icon="fa-function">
        Transformation
      </SidebarRow>
      <SidebarRow to="/data" icon="fa-database">
        Data
      </SidebarRow>
      <SidebarRow to="/dashboards" icon="fa-analytics">
        Dashboards
      </SidebarRow>
      <SidebarRow to="/alerts" icon="fa-bell">
        Alerts
      </SidebarRow>
    </Pane>
    <SidebarRow to="/settings" icon="fa-cog" marginTop="auto">
      Settings
    </SidebarRow>
  </Column>
);

const SidebarRow = ({
  icon,
  to,
  children,
  exact,
  ...props
}: { icon?: string; to: string; exact?: boolean } & UIProps) => {
  return (
    <Pane
      width={180}
      fontWeight={600}
      fontSize={14}
      marginBottom={3}
      {...props}
    >
      <NavLink to={to} paddingX={10} paddingY={12} exact={exact}>
        {icon && <Icon type="fas" name={icon} fixedWidth marginRight={12} />}
        {children}
      </NavLink>
    </Pane>
  );
};

export default Sidebar;
